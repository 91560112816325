<template>
    <div class="share-button">
        <v-popover :hide-on-target-click="true">
            <!-- This will be the popover target (for the events and position) -->
            <div class="tooltip-target b3">
                <img src="@assets/img/icons/share.svg" class="share" alt="Share">
            </div>

            <!-- This will be the content of the popover -->
            <template slot="popover">
                <div class="popover-options share-popover">
                    <social-sharing
                        v-for="network in networks"
                        :key="network.label"
                        class="popover-option"
                        :url="network.url"
                        :title="network.title"
                        :description="network.description"
                        inline-template
                        @click.native="trackShare(network)"
                    >
                        <network :id="network.id" :network="network.name">
                            <img :src="network.iconPath">
                            {{ network.label }}
                        </network>
                    </social-sharing>
                    <span v-clipboard:copy="currentUrl" v-clipboard:success="onCopy" class="popover-option">
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path fill="#505050" fill-rule="evenodd" d="M7.674 22.753l-3.427-3.427A.842.842 0 0 1 4 18.73V5.755c0-.465.377-.842.842-.842h2.26v-1.79c0-.62.502-1.123 1.122-1.123H19.49c.62 0 1.122.503 1.122 1.122v15.842c0 .62-.502 1.122-1.122 1.122h-1.98v2.072a.842.842 0 0 1-.841.842h-8.4a.842.842 0 0 1-.595-.247zM6.98 15.51a.618.618 0 0 0 0 1.236h7.626a.618.618 0 0 0 0-1.236H6.98zm0-3.554a.618.618 0 0 0 0 1.235h7.626a.618.618 0 0 0 0-1.235H6.979zm0-3.555a.618.618 0 1 0 0 1.236h7.626a.618.618 0 1 0 0-1.236H6.979zm1.357-3.488h8.332c.465 0 .842.377.842.842v13.096h1.305c.31 0 .561-.251.561-.561V3.797a.561.561 0 0 0-.561-.562H8.898a.561.561 0 0 0-.56.562v1.116z" />
                        </svg>
                        Copy Link
                    </span>
                </div>
            </template>
        </v-popover>
    </div>
</template>

<script>
import Vue from "vue";
import SocialSharing from "vue-social-sharing";
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

export default {
    name: "ShareButton",
    components: {
        SocialSharing
    },
    props: {
        memod: {
            type: Object,
            required: true
        },
        isSerialized: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            networks: []
        };
    },
    computed: {
        currentUrl() {
            return `${location.origin}/${this.memod.writer.displayname}/${this.memod.slug}-${this.memod.id}${this.isSerialized ? "/part-1" : ""}`
        }
    },
    watch: {
        memod: {
            immediate: true,
            handler() {
                this.setNetworks();
            }
        }
    },
    methods: {
        onCopy() {
            this.$notify({
                group: "app-notifications",
                type: "success",
                text: "Link copied to your clipboard",
                duration: 5000
            });
        },
        setNetworks() {
            this.networks = [];
            this.networks = [
                {
                    url: this.currentUrl,
                    label: "Twitter",
                    name: "twitter",
                    title: this.memod.title,
                    iconPath: require("@assets/img/icons/twitter-share-icon.svg")
                },
                {
                    url: this.currentUrl,
                    title: this.memod.title,
                    label: "Facebook",
                    name: "facebook",
                    // description: ``,
                    iconPath: require("@assets/img/icons/facebook-share-icon.svg")
                },
                {
                    url: this.currentUrl,
                    title: this.memod.title,
                    label: "Email",
                    name: "email",
                    // description: ``,
                    iconPath: require("@assets/img/icons/email-share-icon.svg")
                }
            ]
        },
        trackShare(network) {
            this.$amplitude.logEvent("Share", {
                entity_id: network.name,
                event_name: "Share",
                path: network.url,
                title: network.title
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.share-button {
    .share {
        cursor: pointer;
    }
}

.popover-options.share-popover {
    width: fit-content;

    /deep/ .popover-option {
        cursor: pointer;
        justify-content: flex-start !important;

        svg, img {
            margin-right: 5px;
        }

        &:focus {
            outline: none;
        }
    }
}
</style>
